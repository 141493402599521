html,
body {
    font-family: 'Montserrat', sans-serif;
    margin: 0px;
    height: 100%;
}

body {
    min-height: 100%;
    background-color: rgba(55, 56, 75, 1);
}

@media (min-width: 700px) {
    body {
        padding-top: 64px;
    }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

a {
    text-decoration: none !important;
}
